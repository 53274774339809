body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}


.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }
  
.NotFound {
    padding-top: 100px;
    text-align: center;
  }
  
@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
  }
  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }
  
@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }
  
@media all and (min-width: 480px) {
    .ResetPassword {
      padding: 60px 0;
    }
  
    .ResetPassword form {
      margin: 0 auto;
      max-width: 320px;
    }
  
    .ResetPassword .success {
      max-width: 400px;
    }
  }
  
  .ResetPassword .success {
    margin: 0 auto;
    text-align: center;
  }
  .ResetPassword .success .glyphicon {
    color: grey;
    font-size: 30px;
    margin-bottom: 30px;
  }
  
@media all and (min-width: 480px) {
    .Settings {
      padding: 60px 0;
      margin: 0 auto;
      max-width: 320px;
    }
  }
  .Settings .LoaderButton:last-child {
    margin-top: 15px;
  }
  
@media all and (min-width: 480px) {
    .ChangePassword {
      padding: 60px 0;
    }
  
    .ChangePassword form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
.NewNote form {
    padding-bottom: 15px;
  }
  
  .NewNote form {
    margin: 0 auto;
    max-width: 320px;

  }
  
.Notes form {
    padding-bottom: 15px;
  }
  
  .Notes form {
    margin: 0 auto;
    max-width: 320px;

  }
  
/* LocationUpdateForm.css */

.location-form {
    width: 100%;
    max-width: 1200px; /* Adjust to fit the width of your header */
    margin: 0 auto;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px; /* Adds space between items in the row */
    margin-bottom: 20px; /* Adds space below each row */
    padding: 10px 0; /* Adds padding within each row */
    border-bottom: 1px solid #ddd; /* Optional: adds a border below each row */
}

.form-row input {
    flex: 1 1;
    margin: 5px; /* Adds margin around each input */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    margin-top: 10px; /* Adds space above buttons */
}

.large-button {
    padding: 10px 20px; /* Adjust padding for button size */
    font-size: 16px; /* Increase font size */
    border-radius: 5px; /* Optional: add rounded corners */
    background-color: #007bff; /* Adjust background color */
    color: white; /* Adjust text color */
    border: none; /* Remove border */
    cursor: pointer; /* Change cursor on hover */
}

.large-button:hover {
    background-color: #0056b3; /* Change background color on hover */
}

.center-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Optional: Adds space above the button */
    margin-bottom: 20px;
}

.LoaderButton:last-child {
    /*max-width: 320px;*/
    /*display: block;*/
    /*margin: auto;*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Optional: Adds space above the button */
    margin-bottom: 20px;
}
.center-text {
    text-align: center;
}

.center-text:first-child {
    margin-top: 100px; /* Adds space above the first .center-text element */
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.navbar-nav .nav-item .nav-link,
.navbar-nav .dropdown-item {
  color: #555; /* Default link color */
}

.navbar-nav .nav-item .nav-link.active,
.navbar-nav .dropdown-item.active {
  color: #000; /* Active link color */
  font-weight: bold;
}

.navbar-nav .dropdown-item:hover {
  background-color: #f8f9fa; /* Hover background color */
}

.navbar-nav .dropdown-divider {
  margin: 0.5rem 0; /* Divider spacing */
}

/* Default smaller margin */
.nav-link-spacing {
  margin-right: 10px;
}

/* Larger screens */
@media (min-width: 768px) {
  .nav-link-spacing {
    margin-right: 20px; /* Increase spacing on larger screens */
  }
}

