.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.navbar-nav .nav-item .nav-link,
.navbar-nav .dropdown-item {
  color: #555; /* Default link color */
}

.navbar-nav .nav-item .nav-link.active,
.navbar-nav .dropdown-item.active {
  color: #000; /* Active link color */
  font-weight: bold;
}

.navbar-nav .dropdown-item:hover {
  background-color: #f8f9fa; /* Hover background color */
}

.navbar-nav .dropdown-divider {
  margin: 0.5rem 0; /* Divider spacing */
}

/* Default smaller margin */
.nav-link-spacing {
  margin-right: 10px;
}

/* Larger screens */
@media (min-width: 768px) {
  .nav-link-spacing {
    margin-right: 20px; /* Increase spacing on larger screens */
  }
}
