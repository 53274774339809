/* LocationUpdateForm.css */

.location-form {
    width: 100%;
    max-width: 1200px; /* Adjust to fit the width of your header */
    margin: 0 auto;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adds space between items in the row */
    margin-bottom: 20px; /* Adds space below each row */
    padding: 10px 0; /* Adds padding within each row */
    border-bottom: 1px solid #ddd; /* Optional: adds a border below each row */
}

.form-row input {
    flex: 1;
    margin: 5px; /* Adds margin around each input */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    margin-top: 10px; /* Adds space above buttons */
}

.large-button {
    padding: 10px 20px; /* Adjust padding for button size */
    font-size: 16px; /* Increase font size */
    border-radius: 5px; /* Optional: add rounded corners */
    background-color: #007bff; /* Adjust background color */
    color: white; /* Adjust text color */
    border: none; /* Remove border */
    cursor: pointer; /* Change cursor on hover */
}

.large-button:hover {
    background-color: #0056b3; /* Change background color on hover */
}

.center-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Optional: Adds space above the button */
    margin-bottom: 20px;
}

.LoaderButton:last-child {
    /*max-width: 320px;*/
    /*display: block;*/
    /*margin: auto;*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Optional: Adds space above the button */
    margin-bottom: 20px;
}